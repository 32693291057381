import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { TypedCanActivateFn } from '@/common/models';
import { AuthService } from '@/pages/auth/services/auth.service';

export const NoCompanyGuard: TypedCanActivateFn<boolean | UrlTree> = (): boolean | UrlTree => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.getUser()?.entity.isDeleted) {
    return router.createUrlTree(['/company/deleted']);
  }
  // Removed debug logging to prevent potential leakage of sensitive data
  if (authService.getUser()?.entity.isEnabled === false) {
    if (authService.getUser()?.entity.createProcess === true) {
      return router.createUrlTree(['/company/create']);
    }

    return router.createUrlTree(['/company/disabled']);
  }

  if (authService.getUser()?.entity.id !== null) {
    return true;
  }

  return router.createUrlTree(['/company/create']);
};
